//  Libs
import React, { useContext, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Context
import { HeaderContext } from '../../../../context/header';
import { FooterContext } from '../../../../context/footer';

// Components
import Layout from 'components/layout';
import SEO from 'components/seo';
import { device } from 'components/device';

import BreadCrumb from 'components/breadCrumb';
import Menu from 'components/contactUs/menu';
import DescriptionPage from 'components/contactUs/descriptionPage';
import HeaderIntern from 'components/contactUs/headerIntern';

// Styles
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;

  > h1 {
    width: 100%;
  }

  a {
    color: #45a7df;
  }

  em {
    font-style: normal;
    a {
      color: #fff;
    }
  }

  @media ${device.laptop} {
    display: block;
    overflow-x: hidden;
  }
`;

const Wrapper = styled.div`
  grid-column: 2 / -2;
`;

const ContainerContent = styled.div`
  display: inline-grid;
  grid-column: 5 / 11;
  flex-direction: column;

  div {
    em {
      transition: 0.6s background;
      :hover {
        background: #1d52a3;
      }
    }
  }

  @media ${device.laptop} {
    padding: 0 20px;
    display: flex;

    p {
      width: 100%;
      font-size: 14px;
      em {
        font-size: 14px;
      }
    }
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else {
    return flatten(findFragment.localizations);
  }
};

const ComplimentsAndSuggestions = ({ page, location }) => {
  const { setHasDefaultHeader, setCallCenterBackLink } = useContext(
    HeaderContext
  );

  const { setHasFooter } = useContext(FooterContext);

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setHasDefaultHeader(false);
      setCallCenterBackLink('/fale-conosco/elogio-ou-reclamacao');
      setHasFooter(false);
    }
  }, []);

  const pathname = location.pathname;

  return (
    <Layout>
      <HeaderIntern />
      <SEO dataSeo={page} />
      <GridContainer>
        <Wrapper>
          <BreadCrumb
            markdown={getMarkdown(page, 'ckiadb7r42d890973h3xuicxt', true)}
            marginTop
          />
        </Wrapper>
        <ContainerContent>
          <DescriptionPage
            markdown={getMarkdown(page, 'ckiadj3pc2dly0a77yeq3yyj9', true)}
            widthLarge
            complianceScreen
            newTab
          />
          <Menu intern pathname={pathname} />
        </ContainerContent>
      </GridContainer>
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckiad97zs2cp00962qtfepstt" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <ComplimentsAndSuggestions
            page={response.gcms.site.pages}
            location={location}
          />
        );
      }}
    />
  );
};
